// AuthService.js

import axios from "axios";
import jwtDecode from "jwt-decode";
import AuthService from "@/services/AuthService";
const CryptoJS = require("crypto-js");

const TOKEN_KEY = "jwt";
const instance = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API_URL,
});
export default {
  instance,
  getRole() {
    return localStorage.getItem("userInfo");
  },
  setUserInfo(data){
    // console.log("setuser data",data)
    var stringUserInfo = JSON.stringify(data);

    const encryptedData = CryptoJS.AES.encrypt(
      stringUserInfo,
      "sadfsafa"
    ).toString();   
    localStorage.setItem("userInfo", encryptedData);
  },
  login: async function (email, password) {
    try {
      const response = await instance.post("login", { email, password });

      if (response.status == 200) {
        const token = response.data.data.token;
        // console.log("token",token);
        localStorage.setItem(TOKEN_KEY, token);
        this.setUserInfo(response.data.data)
        return response;
      }
    } catch (error) {
      // console.log("error",error)
      // alert(error);
      alert("Invalid Credentials");

      return false;
    }
  },

  createAdmin: async function (data) {
    try {
      const response = await instance.post("updateAdminUser", data, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  logout: function () {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('userInfo');

  },
  getUser: function () {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const decoded = jwtDecode(token);
      return decoded.user;
    }
    return null;
  },
  getToken: function () {
    return localStorage.getItem(TOKEN_KEY);
  },
  isAuthenticated: function () {
    const token = localStorage.getItem(TOKEN_KEY);
    return token && !this.isTokenExpired(token);
  },
  isTokenExpired: function (token) {
    const decoded = jwtDecode(token);
    const expirationDate = new Date(decoded.exp * 1000);
    return expirationDate < new Date();
  },
  doesEmailExist: async function(userType, email){
    try{
      const response = await instance.get(`doesEmailExist?userType=${userType}&email=${email}`);
      return response.data.emailExists;
    }
    catch(error){
      console.error
    }
  }
};
