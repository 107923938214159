import axios from 'axios'
import AuthService from '@/services/AuthService';
import store from '../store'
const instance = axios.create({
    baseURL: process.env.VUE_APP_ASSESSMENT_API_URL
});
const questionsInstance = axios.create({
    baseURL: process.env.VUE_APP_QUESTIONS_API_URL
});

export default {
    createStaticAssessment: async function (formData) {
        try {
            //console.log("pushing data",formData)
            const response = await instance.post('static-assessment', formData, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },

    getStaticAssessments: async function (status, searchValue="", grades="", subjects="", languages="", optionDate=null) {

        try {
            const response = await instance.get(`static-assessments?status=${status}&searchQuery=${searchValue}&grades=${grades}&subjects=${subjects}&languages=${languages}&optionDate=${optionDate}`,{
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            alert("Something went wrong")
            return error.response;
        }
    },

    getStaticAssessmentCounts: async function () {
        try {
            const response = await instance.get(`static-assessments-counts`,{
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            alert("Something went wrong")
            return error.response;
        }
    },

    deleteStaticAssessmentById: async function (documentId) {
        try {
            const response = await instance.delete(`static-assessments?id=${documentId}`,{
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch(error) {
            alert("Something went wrong")
            return error.response;
        }
    },

    getStaticAssessmentsById: async function (docId, pageSize=10, page=1, reportedQuestion=[], searchQuery='') {
        try {
            const response = await instance.get(`staticAssessmentById?id=${docId}&page=${page}&pageSize=${pageSize}&reportedQuestion=${JSON.stringify(reportedQuestion)}&searchQuery=${searchQuery}`,{
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            alert("Something went wrong")
            return error.response;
        }
    },

    updateStaticAssessment: async function (reqPayload) {
        try {
            const response = await instance.put('staticAssessmentById', reqPayload, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },

    filterStaticAssessmentList: async function ({assessmentStatus=[], languages = [], grades = [], subjects = [], skills = [] ,levels = []}) {
        try {
            const response = await instance.get(`filterStaticAssessment?assessmentStatus=${window.btoa(assessmentStatus)}&languages=${window.btoa(languages)}&grades=${window.btoa(grades)}&subjects=${window.btoa(subjects)}&skills=${window.btoa(skills)}&levels=${window.btoa(levels)}`,{
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch(error) {
            return error.response;
        }
    },
    async getAccessToken(assessmentId) {
        console.log(AuthService.instance);
        return await AuthService.instance.post('mocktestjwtsign',{ userId : store.state.userInfo.id, assessmentId : assessmentId},{headers: {'Authorization': AuthService.getToken()}});
    },

    editAssessmentQuestion: async function (reqPayload) {
        try {
            const response = await instance.put('editQuestionById', reqPayload, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
        } catch(error) {
            return error.response;
        }
    },
    clearMestaUserAssesment: async function(campaignId, grade, studentId, assessmentId){
        try{
            const response = await instance.put('clearMestaUserAssesment', {
                studentId: studentId,
                campaignId: campaignId,
                grade: grade,
                assessmentId: assessmentId,
            })
            return response;
        }
        catch(error){
            return error.response;
        }
    },
    getUserTestReport: async function(studentId, campaignId, grade, assessmentId){
        try{
            const response = await instance.get(`userTestReport?studentId=${studentId}&campaignId=${campaignId}&grade=${grade}&assessmentID=${assessmentId}`)
            return response;
        }
        catch(error){
            return error.response;
        }
    },



}