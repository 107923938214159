<template>
	<div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>
  <div v-else>
  	<v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text">Dashboard</p> <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4  w-fit align-center " style="position: fixed; right: 0;">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img @click="notificationDialog = true" class="cursor" src="../assets/bell 1.svg">
          </v-hover>
        </v-badge>

        <div>

          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32">

        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="pad pb-0 px-8">
	    <v-row class="pa-0">
	    	<v-col>
	    		Dashboard-Mesta
	    	</v-col>
	    	<v-col cols=4 class="py-0">
	    		<v-autocomplete
	            v-model="selectedCampaign"
	            :items="mestaCampaigns"
              item-key="campaignId"
              item-text="campaignName"
              item-value="campaignId"
              return-object
	            filled
	            outlined
              @change=updateData
	          ></v-autocomplete>
	    	</v-col>
	    </v-row>
      <v-card outlined>
      <v-row>
        <!-- <v-card> -->
            <v-col>
                <v-row >
                  <v-col class="ma-4">
                    <span >Student List ({{studentCount}})</span>
                  </v-col>
                  <v-col class=" mt-4">
                  <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  ></v-text-field>
                  </v-col>
                  <v-col cols="1" class="mt-6 mr-2">
                    <img @click="downloadExcel" class="cursor" src="../assets/downloadSimple.svg">
                  </v-col>
                </v-row>
                
                
                <v-card outlined class="ma-4">
                <v-card-text>
                  <template>
                   <v-data-table
                    :headers="headers"
                    :items="formattedAssessmentData"
                    :search="search"
                    :item-key="studentId" 
                    :items-per-page="options.itemsPerPage"
                    :options.sync="options"
                    :loading="isLoading"
                    :footer-props="{
                        itemsPerPageOptions: [10, 20, 25, 30, -1],
                    }"
                    @update:options="debouncedTableAssessments"
                    @click:row="handleRowClick"   
                    >
                  </v-data-table>       
                </template>
                </v-card-text>
                
            </v-card>
            </v-col>
            <v-divider vertical class="my-6"></v-divider>
            <v-col>
              <v-row>
                <v-col cols=12>
                  <v-card>
                    <BarChart :chart-data="chartData"/>
                  </v-card>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-card outlined class="ml-2 mr-4 mb-4">
                    <v-card-text>
                      <v-chip outlined color="grey" class="note full-width-chip">
                        Note: Select a student to know score details
                      </v-chip>

                      <div class="student-details pa-2" >
                        <span>Name: <b>{{selectedStudent.name}}</b></span>
                        <span class="separator"></span>
                        <span>Language: <b>{{selectedStudent.language?.toString()}}</b></span>
                        <span class="separator"></span>
                        <span>Overall Score: <b>{{selectedStudent?.overallScore?.totalSecuredScore}}/10</b></span>
                      </div>
                    </v-card-text>
                    <v-row>
                      <v-col cols="6" class="pl-2">
                        <PieChart :chart-data="pieChartData" />
                      </v-col>
                      <v-col cols="6">
                        <v-list>
                          <v-list-item-group>
                             <v-list-item v-for="(score, index) in selectedScore" :key="index" style="display: flex; align-items: center;" class="custom-list-item">
                              <div 
                                :style="{ 
                                  backgroundColor: getColor(index), 
                                  width: '30px',  /* Adjusted width */
                                  height: '15px', 
                                  marginRight: '8px' 
                                }">
                              </div>
                              <v-row>
                                <v-col cols=9 style="font-size: 14px">{{ score.subject }}</v-col>
                                <v-col> {{ score.securedScore }}/{{ score.totalSubjectScore }}</v-col>
                              </v-row>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>  
        <!-- </v-card> -->
      </v-row>
    </v-card>

	</div>
  </div>
</template>
<script>
import { doc, onSnapshot, collection, query, where, orderBy } from "firebase/firestore";
import { db,app } from "../firebase";
import { Pie } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import * as XLSX from 'xlsx';

// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, ArcElement);

import BarChart from '../components/BarChart.vue'
import PieChart from '../components/PieChart.vue'
import CampaignController from "@/controllers/CampaignController";
import DashboardController from "@/controllers/DashboardController";
import { debounce } from "lodash";
import AuthService from "@/services/AuthService";

export default {
name:"MestaDashboard",
components: {
    PieChart,
    BarChart
  },
data(){
	return {
		notificationDialog:false,
		campaigns:[],
    mestaCampaigns:[],
		selectedCampaign:null,
    assessmentData:[],
    preloader:false,
    gradesData :[],
    headers: [
          { text: 'Name', sortable: false, value: 'name' },
          { text: 'Exam Language', sortable: false, value: 'language' },
          { text: 'Score', sortable: true, value: 'score' },
        ],
    campaignId:'',
    selectedStudent: {},
    selectedScore:[],
    grades: [],
    campaignGrades:{},
    languages: ['English', 'Marathi', 'Hindi'],
    assessmentsGradeData: {},
    studentCount:0,
    search:'',
    options: {},
    isLoading: false
	};
},
watch:{
  search:{
    handler(newValue) {
      if(newValue == null) {
        this.search = ""
      }
      this.debouncedFilteredAssessments()
    }
  }
},
methods:{
	logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
  updateData(){
    console.log('update data',this.selectedCampaign)
    this.campaignId = this.selectedCampaign.campaignId
    this.getAssessmentData();
    
  },
  downloadExcel() {
    if(!this.assessmentData){
      alert("Data not available");
      return;
    }
    console.log('assessment Data',this.assessmentData)
      const headers = ['createdAt','name','email','languageOfExam','score','timeTaken(in minutes)']
      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert data to a format suitable for the Excel file
      const worksheetData = this.formattedAssessmentData?.map(item => [
        item.createdAt,
        item.name,
        item.email,
        item.language.toString(),
        item.overallScore.totalSecuredScore + "/" + item.overallScore.totalScore,
        item.timeTaken,
      ]);

      // Add the headers to the worksheet
      worksheetData.unshift(headers);

      // Create a worksheet from the data
      const ws = XLSX.utils.aoa_to_sheet(worksheetData);

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // Generate a file and prompt download
      XLSX.writeFile(wb, 'MESTA_'+this.selectedCampaign.campaignName+'_Student_List.xlsx');
  },
  async getAssessmentData() {
    console.log("Calling getAssessmentData")
    this.isLoading=true;
    this.assessmentData = [];
    this.grades = [];
    this.selectedStudent = {};
    this.selectedScore = [];
    this.assessmentsGradeData = {};
    this.studentCount = 0;
    console.log('in getAssessmentData')
    let query = "?campaignId="+this.campaignId+"&itemsPerPage="+this.options.itemsPerPage+"&currentPage="+this.options.page+"&search="+this.search;
    const response = await DashboardController.getMestaStudentResults(query)
    this.assessmentData = response.results;
    this.studentCount = response?.totalResults || 0;
      // const campaignsRef = collection(db, "dashboards", "mesta-dashboard","campaigns");
      // const studentRef = collection(campaignsRef,this.campaignId,"students");
     
      // onSnapshot(studentRef,async (snapshot) => {
        
        // snapshot.docs.forEach((doc,index) => {
        //   const studentData = doc.data();
        //   this.assessmentData.push(studentData);
      if(this.assessmentData) { 
        this.selectedStudent = this.assessmentData[0]; // Assign first student data
        this.selectedScore = this.selectedStudent.subjectsScore;
        this.assessmentsGradeData = response.grades
        this.grades = Object.keys(this.assessmentsGradeData);
        console.log('getGradeData',this.assessmentsGradeData)
        console.log('getGrade',this.grades)
      // this.getGradeData();
      // this.studentCount = this.assessmentData.length;
      console.log('this.assessmentData',this.assessmentData)
      } else {
        console.log("No data available");
      }
      this.isLoading=false;
  },
  
  async getGradeData() {
    console.log('getGradeData in function',this.campaignId)
    const dashboardRef = collection(db,"dashboards");
    const mestaCampaignRef = doc(dashboardRef,"mesta-dashboard","campaigns",this.campaignId);
    onSnapshot(mestaCampaignRef,(snapshot) => {
      const grades = snapshot.data();
      if(grades){
        this.assessmentsGradeData = grades
        this.grades = Object.keys(this.assessmentsGradeData);
        console.log('getGradeData',this.assessmentsGradeData)
        console.log('getGrade',this.grades)
      }
      
    })
  },

  async getCampaigns() {
    const response = await CampaignController.getMestaCampaigns();
    this.mestaCampaigns = response.result
        if (this.mestaCampaigns.length > 0) {
          this.selectedCampaign = this.mestaCampaigns[0];
          this.campaignId = this.mestaCampaigns[0].campaignId
        }
  },
  handleRowClick(item) {
      console.log('Row clicked:', item); 
      this.selectedStudent = item
      this.selectedScore = item.subjectsScore || []; // Ensure it's an array
  },
  getColor(index) {
    const colors = ['#BDD1AB', '#86BDF9', '#BCE79F', '#FB80B2'];
    return colors[index % colors.length];
  }, 
},
created(){
  this.debouncedFilteredAssessments = debounce(this.getAssessmentData, 500)
  this.debouncedTableAssessments = debounce(this.getAssessmentData, 200)
  this.getCampaigns();

},
mounted() {
    // this.getGradeData();
},
computed: {
    formattedAssessmentData() {
      return this.assessmentData?.map(item => {
        return {
          ...item,
          score: item.overallScore ? item.overallScore.totalSecuredScore : 0, // Map totalSecuredScore to score
          rank : 1
        };
      });
    },
    pieChartData() {
    console.log('here')
    console.log('this.subjectScore',this.selectedStudent)
    const labels = this.selectedScore?.map(score => score.subject || "Unknown");

    const data = this.selectedScore?.map(score => score.securedScore || 0);
    
    return {
      labels: labels,
      datasets: [
        {
          label: 'Secured Score',
          backgroundColor: ['#BDD1AB', '#86BDF9', '#BCE79F', '#FB80B2'],
          data: data,
        }
      ],
      
    };
  },

  chartData() {
    console.log('in chart data this.assessmentsGradeData',this.assessmentsGradeData)
      const labels = this.grades;
      // console.log()
      console.log('labels in chart Data',this.grades)
      const englishData = this.grades.map(grade => this.assessmentsGradeData[grade].English);
      const marathiData = this.grades.map(grade => this.assessmentsGradeData[grade].Marathi);
      const hindiData = this.grades.map(grade => this.assessmentsGradeData[grade].Hindi);
      // console.log('englishData',englishData)
      return {
        labels,
        datasets: [
          {
            label: 'English',
            backgroundColor: '#D0EEBC',
            data: englishData
          },
          {
            label: 'Marathi',
            backgroundColor: '#86BDF9',
            data: marathiData
          },
          {
            label: 'Hindi',
            backgroundColor: '#FB80B2',
            data: hindiData
          }
        ]
      };
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Number of Assessments'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Grades'
            }
          }
        }
      };
    }
    
  },
};
</script>
<style scoped>

.full-width-chip {
  width: 100%;
}
.v-data-table-header th {
  padding: 2px 16px; /* Adjust padding to decrease header height */
  line-height: 0.5; /* Adjust line height to control the height */
}
.pie-chart {
  position: relative;
}
.note {
  font-weight: bold;
}
.student-details {
  display: flex;
  justify-content: space-between; /* Space out items evenly */
  align-items: center; /* Align items vertically centered */
}
.separator {
  margin: 0 16px; /* Adjust margin for spacing */
}
.custom-list-item {
  margin: 0; /* Remove margin between items */
  padding: 0 0 0 0; /* Adjust vertical padding as needed */
}

.custom-list-item .v-row {
  margin: 0; /* Remove margin from the row */
}

</style>