<template>
  <div v-if="preloader">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div> 
  <div v-else>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position: fixed; z-index: 200; top: 0; width: 98%; padding-top: 16px;">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text underline-on-hover cursor" @click="$router.push('/static-assessment')">Assessments</p>
        <v-icon
          class="breadcrumb-arrow-unselected"
          style="margin-top: 6px !important"
        >mdi-chevron-right
        </v-icon>
        <p class="breadcrumb-text">View Assessment</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon>    
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0">
        <div>
            <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32"/>
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
              <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <v-container fluid class="pad px-8 background">
      <div class="d-flex align-center mb-4">
        <h3 class="mr-auto">{{assessmentDetails?.assessmentName}}</h3>

        <v-text-field v-model="search" solo label="Search here..." prepend-inner-icon="mdi-magnify" hide-details
          class="search-bar border-button rounded-pill mx-3" dense clearable>
        </v-text-field>
          <v-btn @click="filterDialog = true" depressed rounded outlined
            class="border-button mx-3 pl-4 pr-6">
            <v-icon class="pr-2">mdi-tune</v-icon>            
            <div class="d-flex">
              <span>Filter</span>
              <span class="ml-1" v-if="selectedReportedQuestionsFilter.length">
                (1)
              </span>
            </div>
          </v-btn>
          <v-btn @click="downloadAssessment" depressed outlined rounded class="border-button mx-3"><v-icon>mdi-import
              mdi-rotate-90</v-icon>
          </v-btn>

          <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
            <v-card width="570px" height="100vh" class="filterDialogClass">
              <v-row class="mt-4 mx-1">
                <v-col>
                  <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                    Filter
                  </div>
                </v-col>
                <v-col class="d-flex justify-end">
                  <div @click="clearFilterData()" class="text-body1 font-weight-bold black--text cursor">
                    CLEAR FILTER
                  </div>
                </v-col>
              </v-row>

              <v-tabs vertical>
                <div class="ml-3 mt-4 mr-4 filterLine">           
                  <v-tab class="black--text justify-start" active-class="activeTab">Reported Questions
                    <span class="ml-1">
                    </span>
                  </v-tab>
                </div>

                <v-tab-item>
                  <v-card flat height="580px" id="myScroll">
                    <v-card-text>                      
                      <!-- <v-chip v-for="(question, index) in reportedQuestions" :key="index" :value="question.value" elevated>
                        {{ question.label }}
                      </v-chip> -->

                      <v-chip-group v-model="selectedReportedQuestionsFilter" active-class="secondary" column>
                        <v-chip v-for="(question, index) in reportedQuestions" :key="index" :value="question.value" elevated>
                        {{ question.label }}
                      </v-chip>
                    </v-chip-group>
                                       
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>

            <div class="filterDialogButton">
              <v-card-actions class="px-6 pb-3">
                <v-spacer></v-spacer>
                <v-btn rounded depressed class="accent--text font-weight-medium pa-4"
                  @click="filterDialog = false">Cancel</v-btn>
                <v-btn rounded class="accent pa-4"
                  @click="(filterDialog = false,
                  applyFilter())"
                >Apply</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>


      </div>
      <div class="bg-white rounded-lg pa-4 mb-4">
        <v-row>
          <v-col cols="4">
            <div class="d-flex">
              <span class="text-subtitle-2 mr-1">Language - </span>
              <span v-for="(item, index) in assessmentDetails?.availableLanguages" :key="index" class="text-subtitle-2 font-weight-bold">
                {{ item }}<span  v-if="index <  assessmentDetails?.availableLanguages?.length - 1">, </span>
              </span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="d-flex">
              <span class="text-subtitle-2 mr-1">Level - </span>
              <span class="text-subtitle-2 font-weight-bold">{{ assessmentDetails.level }}</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="d-flex justify-center">
              <span class="text-subtitle-2 mr-1">Grade - </span>
              <span class="text-subtitle-2 font-weight-bold">{{ assessmentDetails.grade }}</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="d-flex justify-end">
              <span class="text-subtitle-2 mr-1">Total Questions - </span>
              <span class="text-subtitle-2 font-weight-bold">{{ newQuestionCount }}</span>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="d-flex justify-end">
              <span class="text-subtitle-2 mr-1">Marks - </span>
              <span class="text-subtitle-2 font-weight-bold">{{ assessmentDetails.marksAllotted }}</span>
            </div>
          </v-col>
        </v-row>
        <div class="mt-4">
          <div class="d-flex">
            <span class="text-subtitle-2 mr-1">Skills - </span>
            <span v-for="(item, index) in assessmentDetails?.skills" :key="index" class="text-subtitle-2 font-weight-bold">
              {{ item }} <span  v-if="index <  assessmentDetails?.skills?.length - 1">, </span>
            </span>
          </div>
        </div>
      </div>

      <div class="bg-white pa-4 mb-4">
        <h3 class="mb-4">Questions</h3>
        <v-data-table
          :headers="headers"
          :items="allQuestions"
          :footer-props="{
              itemsPerPageOptions: [10, 20, 25, 30, -1],
          }"            
          class="elevation-0 custom-data-table"
          >
            <template v-slot:[`item.questionStatement`]="{ item }">
              <span v-html="item.questionStatement"></span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <img width="36px" height="36px"  class="cursor" src="../assets/preview_1.svg" @click="redirectToViewQuestion(item.questionId)"/>
              </div>
            </template>
        </v-data-table>
      </div>
    </v-container>

    
  </div>
</template>
<script>
    import AuthService from "../services/AuthService";
    import StaticAssessmentController from "@/controllers/StaticAssessmentController";    
    import { format, startOfToday, startOfYesterday, startOfWeek, startOfMonth, startOfLastWeek, startOfLastMonth, endOfToday, endOfYesterday, endOfWeek, endOfMonth, endOfLastWeek, endOfLastMonth } from 'date-fns';    
    import { debounce } from "lodash";
    import Papa from "papaparse";

    export default {
      name: "AssessmentListingView",
      data() {
        return {
          preloader: true,
          assessmentId: "",
          assessmentDetails: {},
          questionsList: [],
          allQuestions: [],
          newQuestionCount: 0,
          headers: [
            { text: 'Question ID', value: 'questionId', width: '15%'},
            { text: 'Question Statement', value: 'questionStatement', width: '35%'},
            { text: 'Skills', value: 'skill', width: '10%'},
            { text: 'Score', value: 'marksAllotted', width: '10%'},
            { text: 'Difficulty Level', value: 'difficultyLevel', width: '20%'},
            { text: 'Grade', value: 'grade', width: '15%'},
            { text: 'Actions', value: 'actions', sortable: false, width: '20%'},
          ],
          filterDialog: false,
          reportedQuestions: [
            { label: 'Today', value: 'today' },
            { label: 'Yesterday', value: 'yesterday' },
            { label: 'This Week', value: 'this_week' },
            { label: 'Last Week', value: 'last_week' },
            { label: 'This Month', value: 'this_month' },
            { label: 'Last Month', value: 'last_month' },
          ],
          date: null,
          startDate: null,
          endDate: null,  
          filter: {},
          clearFilter: [],
          selectedReportedQuestionsFilter: [],
          displayedQuestions: [],
          assessmentStatus: [],                    
          search: '',
          debouncedSearch: null,
          assessments: [],
          assessmentName: ''
        }
      },      
      watch: {
        selectedReportedQuestionsFilter(newValue) {
          this.updateFilter();
        },
        search(newValue) {
          console.log("search Val :", newValue);
          if (newValue === null)
            this.searchQuery = ""
          this.debouncedSearch();
        },
      },
      created() {
        this.debouncedSearch = debounce(this.fetchAssessmentById, 500)
      },
        mounted() {
            this.assessmentId = this.$route.query.id;
            this.assessmentStatus =  this.$route.query.status;            
            this.pageSize = 10;
            this.page = 1;
            this.fetchAssessmentById();
        },        
        methods: {
            logout() {
                AuthService.logout();
                this.$router.push("/login");
            },
            async fetchAssessmentById() {
                this.isLoading = true;

                if (Object.keys(this.filter).length === 0) {
                  this.filter = [];
                }
          
                try {
                  const params = {                    
                    searchQuery: this.search
                  }

                const response = await StaticAssessmentController.getStaticAssessmentsById(this.assessmentId, this.pageSize, this.page, this.filter, params.searchQuery);

                if(response.status == 200) {
                    this.questionsList = response.data.assessments?.[0]?.questions;
                    this.assessmentName = response.data.assessments?.[0]?.assessmentName;
                    this.assessmentDetails = response.data.assessments[0];
                    this.newQuestionCount =  response.data.assessments?.[0]?.questions?.length;
                    if(this.questionsList?.length > 0) {
                      this.allQuestions = this.questionsList.map(({QuestionDetails, ...rest})=>{
                        return{
                          questionStatement:QuestionDetails.english.questionStatement,
                          ...rest
                          }
                        });
                    }
                    this.preloader = false;
                    this.isLoading = false;
                } else {
                    this.preloader = false;
                    this.isLoading = false;
                }

                } catch(error) {
                  console.log('Error while search', error);
                }

            },
            updateFilter() {                                  
              if (this.selectedReportedQuestionsFilter === 'today') {
                this.filter = {
                  start: format(startOfToday(), 'yyyy-MM-dd 00:00:00'),
                  end: format(endOfToday(), 'yyyy-MM-dd 23:59:59')
                }              
              } else if (this.selectedReportedQuestionsFilter === 'yesterday') {
                this.filter = {
                  start: format(startOfYesterday(), 'yyyy-MM-dd 00:00:00'),
                  end: format(endOfYesterday(), 'yyyy-MM-dd 23:59:59')
              }
              } else if (this.selectedReportedQuestionsFilter === 'this_week') {
                this.filter = {
                  start: format(startOfWeek(new Date()), 'yyyy-MM-dd 00:00:00'),
                  end: format(endOfWeek(new Date()), 'yyyy-MM-dd 23:59:59')
              }
              } else if (this.selectedReportedQuestionsFilter === 'last_week') {
                const lastWeekDate = new Date(new Date().setDate(new Date().getDate() - 7));
                this.filter = {
                  start: format(startOfWeek(lastWeekDate, { weekStartsOn: 1 }), 'yyyy-MM-dd 00:00:00'),
                  end: format(endOfWeek(lastWeekDate, { weekStartsOn: 1 }), 'yyyy-MM-dd 23:59:59')
              }
              } else if (this.selectedReportedQuestionsFilter === 'this_month') {
                this.filter = {
                  start: format(startOfMonth(new Date()), 'yyyy-MM-dd 00:00:00'),
                  end: format(endOfMonth(new Date()), 'yyyy-MM-dd 23:59:59')
                }
              } else if (this.selectedReportedQuestionsFilter === 'last_month') {
                const lastMonthDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
                this.filter = {
                  start: format(startOfMonth(lastMonthDate), 'yyyy-MM-dd 00:00:00'),
                  end: format(endOfMonth(lastMonthDate), 'yyyy-MM-dd 23:59:59')  
                }            
              }
            },
            applyFilter() {               
              this.fetchAssessmentById();
            },
            clearFilterData() {
              this.filterDialog = false;
              this.filter = {};
              this.selectedReportedQuestionsFilter = [];
              this.fetchAssessmentById();               
            },
            redirectToViewQuestion(questionId) {
              this.$router.push({
                path: "/assessment-listing-edit-question",
                name: "assessmentListingEditQuestion",
                query: { questionId: questionId, assessmentId: this.assessmentId }
              });
            },
            async downloadCSV(csvData, fileName) {
              const csv = Papa.unparse(csvData);

              const BOM = '\uFEFF'; 
              const blob = new Blob([BOM + csv], { type: "text/csv;charset=utf-8;" });              
              if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, fileName);
              } else {
                const link = document.createElement("a");
                if (link.download !== undefined) {
                  const url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", fileName);
                  link.style.visibility = "hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }
              this.loadingAssessmentsToDownload = false;
            },
            downloadAssessment(){
              this.loadingAssessmentsToDownload = true;
              // Flatten the data
              const flattenedData = [];
              const fileName = `${this.assessmentName}.csv`;

              this.questionsList.forEach(item => {
                  const baseData = {
                      Product: item.Product,
                      questionId: item.questionId,
                      level: item.level,
                      skill: item.skill,
                      grade: item.grade,
                      "HRIS Unique Role ": item["HRIS Unique Role "],
                      subject: item.subject.join(", "),
                  };

              // Loop through QuestionDetails and create a new entry for each language
                  Object.keys(item.QuestionDetails).forEach(language => {
                  const questionDetail = item.QuestionDetails[language];
                    // Initialize option fields
                  const questionOptions = questionDetail.questionOptions || [];
                  const optionsData = {};

                  questionOptions.forEach((option, index) => {
                      optionsData[`optionKey${index + 1}`] = option.optionKey;
                      optionsData[`optionValue${index + 1}`] = option.optionValue;
                  });

                  const additionalData = {};

                  // Only add dateReported and reportedComment if dateReported is present
                  if (item.dateReported) {
                      additionalData.dateReported = format(item.dateReported, 'yyyy-MM-dd 00:00:00');
                      additionalData.reportedComment = item.reportedComment;
                  }
                      flattenedData.push({
                          ...baseData,
                          strand:item.QuestionDetails[language].strand,
                          subStrand:item.QuestionDetails[language].subStrand,
                          topic:item.QuestionDetails[language].topic,
                          lo1:item.QuestionDetails[language].lo1,
                          lo2:item.QuestionDetails[language].lo2,
                          lo3:item.QuestionDetails[language].lo3,
                          lo4:item.QuestionDetails[language].lo4,
                          questionType: item.questionType,
                          languageOfQuestion: language.charAt(0).toUpperCase() + language.slice(1),
                          questionStatement: item.QuestionDetails[language].questionStatement,
                          questionAssetUrl:item.QuestionDetails[language].questionAssetUrl,
                          ...optionsData,
                          correctAnswer: item.correctAnswer,
                          marksAllotted: item.marksAllotted,
                          answerExplanation:item.QuestionDetails[language].answerExplanation,
                          bloomsTaxonomy: item.bloomsTaxonomy,
                          difficultyLevel: item.difficultyLevel,
                          complexityLevel: item.complexityLevel,
                          proficiencyLevel: item.proficiencyLevel,
                          authoredBy: item.authoredBy,
                          hint:item.QuestionDetails[language].hint,
                          ...additionalData 
                          
                      });
                  });
                  
              });

              this.downloadCSV(flattenedData, fileName);
             

            },
            // downloadAssessment() {
            //   this.loadingAssessmentsToDownload = true;
            //   const fileName = `${this.assessmentName}.csv`;   
            //   console.log('this.questionsList',this.questionsList)
            //   let csvData = this.questionsList.map((question) => {
            //       const languages = ['english', 'hindi', 'marathi'];

            //       const baseData = {
            //           "Product": "MESTA",
            //           "Question Id": question.questionId || 'NA',
            //           "Level": question.level || 'NA',
            //           "Skill": question.skill || 'NA',
            //           "Grade": question.grade || 'NA',
            //           "HRIS Unique Role": question["HRIS Unique Role "] || 'NA',
            //           "Subject": Array.isArray(question.subject) ? question.subject.join(', ') : 'NA',
            //           "QuestionType": question.questionType || 'NA',
            //           "Correct Answer": question.correctAnswer || 'NA',
            //           "Marks Allotted": question.marksAllotted || 'NA',
            //           "Blooms Taxonomy": question.bloomsTaxonomy || 'NA',
            //           "Difficulty Level": question.difficultyLevel || 'NA',
            //           "Complexity Level": question.complexityLevel || 'NA',
            //           "Proficiency Level": question.proficiencyLevel || 'NA',
            //           "Authored By": question.authoredBy || 'NA',
            //       };

            //       const languageFields = {};
            //       languages.forEach(lang => {
            //           const langDetails = question.QuestionDetails[lang] || {};

            //           languageFields[`Question Statement (${lang})`] = langDetails.questionStatement || 'NA';
            //           languageFields[`Question Asset URL (${lang})`] = langDetails.questionAssetUrl || 'NA';
            //           languageFields[`Strand (${lang})`] = langDetails.strand || 'NA';
            //           languageFields[`Sub-Strand (${lang})`] = langDetails.subStrand || 'NA';
            //           languageFields[`Topic (${lang})`] = langDetails.topic || 'NA';
            //           languageFields[`Lo1 (${lang})`] = langDetails.lo1 || 'NA';
            //           languageFields[`Lo2 (${lang})`] = langDetails.lo2 || 'NA';
            //           languageFields[`Lo3 (${lang})`] = langDetails.lo3 || 'NA';
            //           languageFields[`Lo4 (${lang})`] = langDetails.lo4 || 'NA';
            //           languageFields[`Answer Explanation (${lang})`] = langDetails.answerExplanation || 'NA';
            //           languageFields[`Hint (${lang})`] = langDetails.hint || 'NA';

            //           // Handle options for each language
            //           const options = langDetails.questionOptions || [];
            //           for (let i = 0; i < 4; i++) {
            //               languageFields[`Option Key ${i + 1} (${lang})`] = options[i]?.optionKey || 'NA';
            //               languageFields[`Option Value ${i + 1} (${lang})`] = options[i]?.optionValue || 'NA';
            //           }
            //       });

            //       return {
            //           ...baseData,
            //           ...languageFields
            //       };
            //   });

            //   this.downloadCSV(csvData, fileName);
            // }

        }
      }
</script>
<style scoped>
  
</style>